body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.container {
  background-image: url("../public/assets/background.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.icon-image-container {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.rotated img {
  transform: scaleX(-1);
}

.signal {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.signal-active{
  background-color: rgb(255, 0, 0)
}

.healthBar {
  display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    /* background-color: #f0f0f0; */
    /* border-radius: 5px; */
}

.bar-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bar-label {
  width: 50px;
  font-weight: bold;
  color: yellow;
}

/* .bar {
  flex-grow: 1;
  height: 20px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
} */

.bar {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: #444;
  border-radius: 5px;
  overflow: hidden;
}

.percentage-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.bar-fill {
  height: 100%;
  transition: width 0.3s ease-in-out;
}

.health-bar .bar-fill {
  background-color: #2bef52;
  /* Green for health */
}

.mana-bar .bar-fill {
  background-color: #2196F3;
  /* Blue for mana */
}

.icon-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
  /* background-color: rgba(0, 0, 0, 0.5); */
  /* Optional: adds a semi-transparent background */
}

.content {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: larger;
}

.status-indicator
{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
}

.status{
  padding: 5px 10px;
  border-radius: 4px;
  /* color: #47e70d; */
  color: #ff0000;
  font-size: 1.5rem;
}